import ReactDOM from "react-dom/client"
import "./index.css"
import { ThemeProvider } from "@mui/material/styles"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import theme from "./theme"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("limit"))
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <App />
    </LocalizationProvider>
  </ThemeProvider>
  // </React.StrictMode>
)
